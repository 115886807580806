import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppButton } from 'src/components/button';
import { Charges } from 'src/components/charges';
import { Copyright } from 'src/components/copyright';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { PaymentCode } from 'src/components/payment-code';

import { BRANDS } from 'src/configuration/constants';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { setPaymentCode, setPaymentCodeFlowFlag, setPaymentLinkFlow } from 'src/store/app/reducer';
import { saveBrandName } from 'src/store/config/reducer';
import { cancelPayment } from 'src/store/payment/actions.thunks';
import { PreparePaymentDTO } from 'src/store/payment/models';
import { generateJWTToken, prepaymentShortCodeRequest } from 'src/store/user/actions.thunks';
import { saveGeneratedJWTToken } from 'src/store/user/reducer';
import { selectPrepayment } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import {
    StyledButtonSection,
    StyledCancelButton,
    StyledConfirmButton,
    StyledLabel,
    StyledLabelText,
    StyledPaymentCodeSection,
    StyledTextBlock,
    StyledTitle,
} from './payment-confirmation.styled';

export const PaymentConfirmation = () => {
    const route = useAppRoute();
    const dispatch = useDispatch();

    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);

    const prepayment = useSelector(selectPrepayment);

    const [prepaymentShortAction, [isPrepaymentShortLoading], [prepaymentShortError]] =
        useThunkAction(prepaymentShortCodeRequest);

    const [generateJWTTokenAction, [isGenerateJWTTokenLoading], [generateJWTTokenError]] =
        useThunkAction(generateJWTToken);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError]] =
        useThunkAction(cancelPayment);

    const { code: paymentCodeVal } = useParams<{ code: string }>();

    useEffect(() => {
        dispatch(saveGeneratedJWTToken(null));
    }, [dispatch]);

    useEffect(() => {
        if (!paymentCodeVal) {
            return;
        }

        dispatch(setPaymentCode(paymentCodeVal));

        generateJWTTokenAction({ paymentLinkToken: paymentCodeVal }).then(r => {
            if (!r.success) return;

            prepaymentShortAction({ shortCode: paymentCodeVal }).then(r => {
                if (!r.success) return;

                const payload = r.payload as { amount: number };

                if (!payload.amount) {
                    dispatch(setPaymentLinkFlow('CONSENT_LINK'));
                    route(AppRoutes.consentLink);
                    return;
                }
            });
        });
    }, [dispatch, generateJWTTokenAction, paymentCodeVal, prepaymentShortAction, route]);

    const handleContinueButtonClick = useCallback(() => {
        if (!prepayment) {
            return;
        }

        route(
            AppRoutes.buyNowPayLater,
            undefined,
            `amount=${prepayment?.amount}&merchantId=${prepayment.merchantId}`
        );
    }, [route, prepayment]);

    useEffect(() => {
        dispatch(setPaymentCodeFlowFlag(!!paymentCodeVal));
    }, [dispatch, paymentCodeVal]);

    useEffect(() => {
        dispatch(saveBrandName(BRANDS.CHARGEE));
    }, [dispatch]);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!prepaymentShortError || !!generateJWTTokenError || !!cancelPaymentError;
    }, [cancelPaymentError, generateJWTTokenError, prepaymentShortError]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    const prepaymentResponse: PreparePaymentDTO | undefined = useMemo(() => {
        if (!prepayment) {
            return;
        }

        return {
            amount: prepayment.amount,
            cardAuthMode: 'NEVER',
            currency: 'usd',
            customerFee: prepayment.customerFee,
            merchantOrderId: prepayment.merchantOrderId,
            totalAmount: prepayment.totalAmount,
            bnplMerchantId: null,
            bnplMerchantName: null,
            financingOption: null,
        };
    }, [prepayment]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const handleCancelButtonClick = useCallback(() => {
        if (!prepayment) {
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: prepayment.amount,
                currency: 'USD',
                description: prepayment.description,
                merchantOrderId: prepayment.merchantOrderId,
            },
        }).then(r => {
            if (!r.success) {
                return;
            }
            setIsCancelPaymentSuccess(true);
        });
    }, [cancelPaymentAction, prepayment]);

    return (
        <StyledPageWithPaddingContainer>
            <Loader
                isShowing={isPrepaymentShortLoading || isGenerateJWTTokenLoading || isCancelPaymentLoading}
            />
            <StyledTitle>
                <span>{TEXT_VARS.TITLE.PAYMENT_CONFIRMATION}</span>
            </StyledTitle>
            {prepayment && (
                <>
                    <StyledPaymentCodeSection>{TEXT_VARS.COMMON_TEXT.DETAILS}</StyledPaymentCodeSection>
                    <StyledTextBlock>
                        <StyledLabel>From:</StyledLabel>
                        <StyledLabelText>{prepayment.merchantName}</StyledLabelText>
                    </StyledTextBlock>
                    {prepayment.customer && (
                        <StyledTextBlock>
                            <StyledLabel>To:</StyledLabel>
                            <StyledLabelText>{prepayment.customer.name}</StyledLabelText>
                        </StyledTextBlock>
                    )}
                    {prepayment.description && (
                        <StyledTextBlock>
                            <StyledLabel>Description:</StyledLabel>
                            <StyledLabelText>{prepayment.description}</StyledLabelText>
                        </StyledTextBlock>
                    )}
                    {prepayment.merchantOrderId && (
                        <StyledTextBlock>
                            <StyledLabel>Order Id:</StyledLabel>
                            <StyledLabelText>{prepayment.merchantOrderId}</StyledLabelText>
                        </StyledTextBlock>
                    )}
                </>
            )}
            <StyledPaymentCodeSection>
                {TEXT_VARS.COMMON_TEXT.PAYMENT_CODE}
                <PaymentCode paymentCode={paymentCodeVal} />
            </StyledPaymentCodeSection>
            {prepaymentResponse && (
                <Charges isBrandChargee paymentResponse={prepaymentResponse} skipLabelSection />
            )}
            <StyledButtonSection>
                <StyledConfirmButton>
                    <AppButton appButtonType="Continue" onClick={handleContinueButtonClick}>
                        {TEXT_VARS.BUTTON.CONFIRM}
                    </AppButton>
                </StyledConfirmButton>
                <StyledCancelButton>
                    <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                        {TEXT_VARS.BUTTON.CANCEL}
                    </AppButton>
                </StyledCancelButton>
            </StyledButtonSection>
            <Copyright />
            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    isCancelPaymentSuccess
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={handleCancelButtonClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    type="error"
                />
            )}
        </StyledPageWithPaddingContainer>
    );
};
