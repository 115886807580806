import { styled } from '@mui/material/styles';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledSubTitleSection = styled('div', {
    shouldForwardProp: prop => prop !== '$isChargee',
})<{ $isChargee: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${props => props.$isChargee && '35px'};

    .MuiFab-root {
        background: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main};
        color: white;
        margin-bottom: 5px;
        z-index: 1;
        box-shadow: none;

        &:hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }

        &:disabled {
            background: lightGray;
        }
    }
`;

export const StyledSubTitle = styled('div')`
    ${AppTypography.regularText};
    font-size: 20px;
    color: ${AppColors.primaryButtonTextColor};
    align-self: center;
`;

export const StyledBodySection = styled('div')`
    margin-top: 11px;
    margin-bottom: 120px;
    border: 1px solid;
    border-radius: 15px;
    background: ${AppColors.lighterGray2};
`;

export const StyledBankAccounts = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const StyledBodyText = styled('div')`
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};
`;

export const StyledConfirmButton = styled('div')`
    align-self: center;
`;

export const StyledCancelButton = styled('div')`
    align-self: center;
    margin: 25px 0 0;
`;

export const StyledBorderBetweenAccounts = styled('div')`
    align-self: center;
    width: 97%;
    height: 0;
    opacity: 0.2;
    border: solid 1px #707070;
`;

export const StyledStepBar = styled('div')`
    padding-bottom: ${units.p26}px;
`;

export const StyledSelectBankAccountWrapper = styled('div')`
    align-self: center;
    width: 375px;

    iframe {
        position: absolute;
        z-index: 9999;
        background: white;
        margin-top: 50px;
    }
`;
