import { Theme } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useThunkAction } from 'src/hooks/useThunkAction.ts';

import { setBranding } from 'src/store/app/reducer.ts';
import { brandInfer } from 'src/store/config/actions.thunks.ts';
import { BrandDTO } from 'src/store/config/models.ts';
import { selectJWTToken } from 'src/store/user/selectors.ts';

export const useBranding = (theme: Theme) => {
    const dispatch = useDispatch();
    const token = useSelector(selectJWTToken)?.idToken;

    const [brandTheme, setBrandTheme] = useState(theme);

    const [brandInferAction] = useThunkAction(brandInfer);

    useEffect(() => {
        if (token) {
            const merchantId = (jwtDecode(token) as { MERCHANT_ID: string })?.MERCHANT_ID;

            if (merchantId) {
                brandInferAction(merchantId).then((r: any) => {
                    if (!r.success) return;

                    dispatch(setBranding(r.payload.name));
                    setBrandTheme(prevTheme => ({ ...prevTheme, brandTheme: r.payload as BrandDTO }));
                });
            }
        }
    }, [brandInferAction, dispatch, token]);

    return brandTheme;
};
