import ReactInputMask from '@mona-health/react-input-mask';
import { TextField } from '@mui/material';
import valid from 'card-validator';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppInput } from 'src/components/app-input';
import { AppButton } from 'src/components/button';
import { Copyright } from 'src/components/copyright';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';

import { getApplicationConfig } from 'src/configuration/config.ts';
import { EMAIL_VALIDATOR_REGEX, NAME_VALIDATOR_REGEX, US_PHONE_REGEX } from 'src/configuration/validators';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import {
    StyledBody,
    StyledCancelButton,
    StyledCardInfo,
    StyledCardRowText,
    StyledConfirmText,
    StyledContinueButton,
    StyledInputs,
    StyledTitle,
} from 'src/screens/enter-basic-info/enter-basic-info.styled';

import {
    selectChangeFlow,
    selectCustomerTypeChangeFlow,
    selectFirstRenderBankAcc,
    selectLinkFlow,
    selectVirtualCustomerId,
} from 'src/store/app/selectors';
import { mergeCustomer } from 'src/store/customer/actions.thunk.ts';
import { cancelPayment } from 'src/store/payment/actions.thunks';
import {
    createOrUpdateCustomer,
    generateJWTToken,
    getCurrentCustomer,
    getPubKey,
    signOut,
} from 'src/store/user/actions.thunks';
import { saveResponseError } from 'src/store/user/reducer.ts';
import {
    selectCustomer,
    selectIsCustomerNew,
    selectPrepayment,
    selectQueryParams,
    selectUserEmail,
    selectUserName,
    selectUserPhone,
} from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { getEncryptedData } from 'src/utils/encryptCard.ts';

const { env } = getApplicationConfig();

const ENTER_BASIC_INFO_CANCEL_CALLBACK = 'cancelCallback';
const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

const getExpMonth = (expDate: string) => parseInt(expDate.slice(0, 2));
const getExpYear = (expDate: string) => parseInt(expDate.slice(5, 7));

const checkIsCardExpDateValid = (expDate: string) => {
    const expMonth = getExpMonth(expDate);
    const expYear = getExpYear(expDate);
    if (!expMonth || !expYear) return false;

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const fullExpYear = 2000 + expYear;
    if (currentYear > fullExpYear) return false;

    const currentMonth = currentDate.getMonth() + 1;
    if (currentYear === fullExpYear && currentMonth > expMonth) return false;

    return true;
};

type MaskedState = { nextState: { value: string } };

const beforeMaskedStateChange = ({ nextState }: MaskedState) => {
    const { value } = nextState;

    if (value.length >= 5 && value[0] !== '0' && value[0] !== '1') {
        nextState.value = '0' + value[0];
    } else if (value.length === 5 && value[0] === '1' && parseInt(value[1]) > 2) {
        nextState.value = '12';
    } else if (value.length === 5 && value[0] === '0' && value[1] === '0') {
        nextState.value = '01';
    }
    return nextState;
};

const checkIsCardNumberValid = (receivedCardNumber: string, cardNumber: string) =>
    receivedCardNumber
        ? valid.number(cardNumber, { skipLuhnValidation: env == 'DEV' || env == 'STAGE' }).isValid
        : true;

export const EnterBasicInfo = () => {
    const route = useAppRoute();
    const dispatch = useDispatch();

    const [
        createOrUpdateCustomerAction,
        [isCreateOrUpdateCustomerLoading],
        [updatingError, setUpdatingError],
    ] = useThunkAction(createOrUpdateCustomer);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError, setCancelPaymentError]] =
        useThunkAction(cancelPayment);

    const [
        generateJWTTokenAction,
        [isGenerateJWTTokenLoading],
        [generateJWTTokenError, setGenerateJWTTokenError],
    ] = useThunkAction(generateJWTToken);

    const [
        getCurrentCustomerAction,
        [isLoadingGetCurrentCustomer],
        // [getCurrentCustomerError, setGetCurrentCustomerError],
    ] = useThunkAction(getCurrentCustomer);

    const [getPubKeyAction] = useThunkAction(getPubKey);
    const [mergeCustomerAction, [isMergeCustomerLoading], [mergeCustomerError, setMergeCustomerError]] =
        useThunkAction(mergeCustomer);

    const queryParams = useSelector(selectQueryParams);
    const linkFlow = useSelector(selectLinkFlow);
    const currentCustomer = useSelector(selectCustomer);
    const currentUserName = useSelector(selectUserName);
    const currentUserEmail = useSelector(selectUserEmail);
    const currentUserPhone = useSelector(selectUserPhone);
    const isCustomerNew = useSelector(selectIsCustomerNew);
    const prepayment = useSelector(selectPrepayment);
    const isFirstRenderBankAcc = useSelector(selectFirstRenderBankAcc);
    const isChangeFlow = useSelector(selectChangeFlow);
    const virtualCustomerId = useSelector(selectVirtualCustomerId);
    const [receivedCardNumber, setReceivedCardNumber] = useState('');
    const customerType = useSelector(selectCustomerTypeChangeFlow);

    const singUpFirstName = currentUserName?.trim().split(/\s+/)[0];
    const singUpLastName = currentUserName?.trim().split(/\s+/)[1];

    const [firstName, setFirstName] = useState(
        queryParams?.firstName || currentCustomer?.firstName || currentCustomer?.name || singUpFirstName || ''
    );

    const [lastName, setLastName] = useState(
        queryParams?.lastName || currentCustomer?.lastName || currentCustomer?.name || singUpLastName || ''
    );

    const [email, setEmail] = useState(
        queryParams?.email || currentCustomer?.email || currentUserEmail || ''
    );

    const [phoneNumber, setPhoneNumber] = useState(
        queryParams?.phone || currentCustomer?.phoneNumber || currentUserPhone || ''
    );

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isInputError, setIsInputError] = useState(false);
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [expDate, setExpDate] = useState('');
    const [pubKey, setPubKey] = useState('');

    const authData = useMemo(() => {
        return prepayment
            ? { paymentLinkToken: prepayment.shortCode, merchantId: prepayment.merchantId }
            : { apiToken: queryParams?.apiToken };
    }, [prepayment, queryParams?.apiToken]);

    const isConsentFlow = linkFlow === 'CONSENT_LINK';
    const isCardNumberValid = isFirstRender || checkIsCardNumberValid(receivedCardNumber, cardNumber);
    const isCardExpDateValid = isFirstRender || checkIsCardExpDateValid(expDate);
    const cardExpDateHelperText = isCardExpDateValid ? undefined : TEXT_VARS.ERRORS.DATE_IS_INVALID;
    const cardNumberHelperText = isCardNumberValid ? undefined : TEXT_VARS.ERRORS.YOUR_CARD_IS_INVALID;

    const isContinueBtnDisabled =
        isChangeFlow && receivedCardNumber
            ? !(firstName && email) || !isCardNumberValid || !isCardExpDateValid
            : !(firstName && email);

    const isErrorDisplayed = !!(
        updatingError ||
        cancelPaymentError ||
        generateJWTTokenError ||
        mergeCustomerError
    );

    const handleInputCardNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCardNumber(e.target.value);
    }, []);

    const handleExpDateChange = useCallback((e: ChangeEvent<{ value: unknown }>) => {
        setExpDate(e.target.value as string);
    }, []);

    const createOrUpdateCustomerFunc = useCallback(async () => {
        if (isConsentFlow) {
            route(AppRoutes.customerSelectYourBankAccount);
            return;
        }

        await createOrUpdateCustomerAction({ name: firstName, lastName: lastName, email, phoneNumber }).then(
            (r: any) => {
                if (!r.success) return;

                generateJWTTokenAction(authData).then(async () => {
                    if (!r.success) return;

                    let isMergeError = false;

                    if (isChangeFlow && virtualCustomerId) {
                        const expMonth = getExpMonth(expDate);
                        const expYear = getExpYear(expDate);
                        const encryptedData =
                            receivedCardNumber &&
                            (await getEncryptedData({ publicKey: pubKey, cardNumber, expMonth, expYear }));

                        customerType === 'ANONYMOUS' &&
                            (await mergeCustomerAction({
                                virtualCustomerId,
                                data: { encryptedData: receivedCardNumber ? encryptedData : '' },
                            }).then(r => {
                                if (!r.success && r.error.message.status == '412') {
                                    isMergeError = true;
                                    setReceivedCardNumber(r.error.message.response.data.reducedCardNumber);
                                    setIsFirstRender(true);
                                    setMergeCustomerError(undefined);
                                    return;
                                }

                                if (!r.success) {
                                    isMergeError = true;
                                    return;
                                }
                            }));
                    }

                    !isMergeError && setTimeout(() => route(AppRoutes.customerSelectYourBankAccount), 1000);
                });
            }
        );
    }, [
        isConsentFlow,
        createOrUpdateCustomerAction,
        firstName,
        lastName,
        email,
        phoneNumber,
        route,
        generateJWTTokenAction,
        authData,
        isChangeFlow,
        virtualCustomerId,
        expDate,
        receivedCardNumber,
        pubKey,
        cardNumber,
        customerType,
        mergeCustomerAction,
        setMergeCustomerError,
    ]);

    const handleContinueClick = useCallback(() => {
        setIsFirstRender(false);

        if (
            isChangeFlow &&
            receivedCardNumber &&
            !(checkIsCardNumberValid(receivedCardNumber, cardNumber) && checkIsCardExpDateValid(expDate))
        ) {
            return;
        }

        if (isInputError || !firstName || !email || !phoneNumber) return;
        createOrUpdateCustomerFunc();
    }, [
        isChangeFlow,
        receivedCardNumber,
        cardNumber,
        expDate,
        isInputError,
        firstName,
        email,
        phoneNumber,
        createOrUpdateCustomerFunc,
    ]);

    const handleOpenCancelPaymentPopup = useCallback(() => setIsCancelPopupDisplayed(true), []);

    const handleCancelClick = useCallback(() => {
        if (!queryParams) {
            setCancelPaymentError('QueryParams unavailable, but expected');
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                description: queryParams.description,
                merchantOrderId: queryParams.orderId,
            },
        })
            .then(r => {
                if (!r.success) return;
                setIsCancelPaymentSuccess(true);
            })
            .finally(() => {
                window.top?.postMessage({ cancelAction: ENTER_BASIC_INFO_CANCEL_CALLBACK }, '*');
            });
    }, [cancelPaymentAction, queryParams, setCancelPaymentError]);

    const handleInfoLayoutActionClick = useCallback(() => {
        setUpdatingError(undefined);
        setCancelPaymentError(undefined);
        setMergeCustomerError(undefined);
        setGenerateJWTTokenError(undefined);
    }, [setUpdatingError, setCancelPaymentError, setMergeCustomerError, setGenerateJWTTokenError]);

    useEffect(() => {
        if (isChangeFlow) {
            getCurrentCustomerAction().then((r: any) => {
                if (!r.success) return;
                dispatch(saveResponseError(undefined));
                generateJWTTokenAction(authData);
            });
        }
    }, [authData, dispatch, generateJWTTokenAction, getCurrentCustomerAction, isChangeFlow]);

    useEffect(() => {
        if (!currentCustomer) return;

        currentCustomer.firstName && setFirstName(currentCustomer.firstName);
        currentCustomer.lastName && setLastName(currentCustomer.lastName);
        currentCustomer.email && setEmail(currentCustomer.email);
        currentCustomer.phoneNumber && setPhoneNumber(currentCustomer.phoneNumber);
    }, [currentCustomer]);

    useEffect(() => {
        setUpdatingError(undefined);
        setCancelPaymentError(undefined);
    }, [setUpdatingError, setCancelPaymentError]);

    useEffect(() => {
        const handleKeypress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') handleContinueClick();
        };
        document.addEventListener('keydown', handleKeypress);

        return () => document.removeEventListener('keydown', handleKeypress);
    }, [handleContinueClick]);

    useEffect(() => {
        const handler = async (event: MessageEvent) => {
            if (event.data.cancelPayment === CANCEL_PAYMENT) {
                handleCancelClick();
                await signOut();
                window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
            }
        };
        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler);
    }, [handleCancelClick]);

    useEffect(() => {
        if (!isCustomerNew && firstName && email && phoneNumber && isFirstRenderBankAcc && !isChangeFlow) {
            createOrUpdateCustomerFunc();
        }
    }, [
        createOrUpdateCustomerFunc,
        email,
        isCustomerNew,
        firstName,
        phoneNumber,
        isFirstRenderBankAcc,
        isChangeFlow,
    ]);

    useEffect(() => {
        if (isChangeFlow) {
            getPubKeyAction().then(async r => {
                if (!r.success) return;
                const payload = r.payload as { publicKey: string };
                setPubKey(payload.publicKey);
            });
        }
    }, [getPubKeyAction, isChangeFlow]);

    return (
        <>
            <StyledPageWithPaddingContainer $isPointerEventsDisabled={!!updatingError}>
                <Loader
                    isShowing={
                        isCreateOrUpdateCustomerLoading ||
                        isCancelPaymentLoading ||
                        isGenerateJWTTokenLoading ||
                        isLoadingGetCurrentCustomer ||
                        isMergeCustomerLoading
                    }
                />
                <StyledTitle>
                    <span>{TEXT_VARS.TITLE.COMPLETE_YOUR_PROFILE}</span>
                </StyledTitle>
                <StyledInputs>
                    <AppInput
                        className="input"
                        handleInputChange={setFirstName}
                        inputErrorText={TEXT_VARS.ERRORS.NAME_IS_INVALID}
                        inputValidationRegexp={NAME_VALIDATOR_REGEX}
                        inputValue={firstName}
                        label="First Name"
                        name="first-name-input"
                        placeholder="First Name ..."
                        setIsValidationError={setIsInputError}
                    />
                    <AppInput
                        className="input"
                        firstRender={isFirstRender}
                        handleInputChange={setLastName}
                        inputErrorText={TEXT_VARS.ERRORS.NAME_IS_INVALID}
                        inputValidationRegexp={NAME_VALIDATOR_REGEX}
                        inputValue={lastName}
                        label="Last Name"
                        name="last-name-input"
                        placeholder="Last Name ..."
                        setIsValidationError={setIsInputError}
                    />
                    <AppInput
                        className="input"
                        handleInputChange={setEmail}
                        inputErrorText={TEXT_VARS.ERRORS.EMAIL_IS_INVALID}
                        inputValidationRegexp={EMAIL_VALIDATOR_REGEX}
                        inputValue={email}
                        label="Email"
                        name="email-input"
                        placeholder="email"
                        setIsValidationError={setIsInputError}
                    />
                    <AppInput
                        className="input"
                        firstRender={isFirstRender}
                        handleInputChange={setPhoneNumber}
                        inputErrorText={TEXT_VARS.ERRORS.PHONE_NUMBER_IS_INVALID}
                        inputValidationRegexp={US_PHONE_REGEX}
                        inputValue={phoneNumber}
                        label="Phone Number"
                        name="phone-input"
                        placeholder="Phone number ..."
                        setIsValidationError={setIsInputError}
                    />
                </StyledInputs>
                {isChangeFlow && receivedCardNumber && (
                    <>
                        <StyledConfirmText>
                            Confirm your card ending in <span>{receivedCardNumber}</span>
                        </StyledConfirmText>
                        <StyledBody>
                            <StyledCardInfo>
                                <StyledCardRowText>
                                    <span>{TEXT_VARS.COMMON_TEXT.CARD_NUMBER}</span>
                                    <ReactInputMask
                                        mask="9999999999999999999"
                                        // max card length - 19 digits
                                        onChange={handleInputCardNumberChange}
                                        value={cardNumber}
                                    >
                                        <TextField
                                            className="card-number"
                                            error={!isCardNumberValid}
                                            helperText={cardNumberHelperText}
                                            placeholder="************ 1234"
                                        />
                                    </ReactInputMask>
                                </StyledCardRowText>
                                <StyledCardRowText>
                                    <span>{TEXT_VARS.COMMON_TEXT.EXPIRY_DATE}</span>
                                    <ReactInputMask
                                        beforeMaskedStateChange={beforeMaskedStateChange}
                                        mask="99 / 99"
                                        onChange={handleExpDateChange}
                                        value={expDate}
                                    >
                                        <TextField
                                            className="exp-cvv-text"
                                            error={!isCardExpDateValid}
                                            helperText={cardExpDateHelperText}
                                            placeholder="MM / YY"
                                            type="text"
                                        />
                                    </ReactInputMask>
                                </StyledCardRowText>
                            </StyledCardInfo>
                        </StyledBody>
                    </>
                )}
                <StyledContinueButton>
                    <AppButton
                        appButtonType="Continue"
                        disabled={isContinueBtnDisabled}
                        onClick={handleContinueClick}
                    >
                        {TEXT_VARS.BUTTON.CONTINUE}
                    </AppButton>
                </StyledContinueButton>
                {!isChangeFlow && (
                    <StyledCancelButton>
                        <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                            {TEXT_VARS.BUTTON.CANCEL}
                        </AppButton>
                    </StyledCancelButton>
                )}
                <Copyright />
            </StyledPageWithPaddingContainer>
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    isCancelPaymentSuccess={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={handleCancelClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    type="error"
                />
            )}

            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </>
    );
};
