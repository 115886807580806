import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentState, PreparePaymentDTO } from 'src/store/payment/models';

const INITIAL_STATE: PaymentState = {};

const paymentReducer = createSlice({
    name: 'PaymentState',
    initialState: INITIAL_STATE,
    reducers: {
        clearPayments: () => INITIAL_STATE,
        savePreparePaymentResponse: (state, action: PayloadAction<PreparePaymentDTO>) => {
            state.preparePayment = action.payload;
        },
        saveCreatePaymentResponse: (state, action: PayloadAction<PreparePaymentDTO>) => {
            state.createPayment = action.payload;
        },
        saveFinancingOption: (state, action: PayloadAction<string>) => {
            state.selectedFinancingOption = action.payload;
        },
        clearFinancingOption: state => {
            state.selectedFinancingOption = undefined;
        },
    },
});

export const {
    clearPayments,
    saveCreatePaymentResponse,
    savePreparePaymentResponse,
    saveFinancingOption,
    clearFinancingOption,
} = paymentReducer.actions;
export default paymentReducer.reducer;
