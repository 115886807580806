import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import animationData from 'src/assets/img/lottie/loader.json';
import animationDataNeutral from 'src/assets/img/lottie/neutral-load-anim.json';

import { StyledLoader } from 'src/components/loader/loader.styled';

import { selectBranding } from 'src/store/app/selectors.ts';

import { StyledExtendable } from 'src/utils/sharedModels';

interface LoaderProps {
    isShowing: boolean;
    relative?: boolean;
    withoutBackground?: boolean;
    delayTimeMilliseconds?: number;
}

const defaultLottieOptions = (isBrandNeutral: boolean) => ({
    loop: true,
    autoplay: true,
    animationData: isBrandNeutral ? animationData : animationDataNeutral,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
});

export const Loader: React.FC<LoaderProps & StyledExtendable> = props => {
    const [isLoaderHidden, setIsLoaderHidden] = useState(true);

    const branding = useSelector(selectBranding);
    const isBrandDefault = branding === 'DEFAULT_BRAND';

    useEffect(() => {
        const _delay = props.delayTimeMilliseconds ? props.delayTimeMilliseconds : 0;
        setTimeout(() => {
            setIsLoaderHidden(false);
        }, _delay);
        return () => setIsLoaderHidden(true);
    }, [props.delayTimeMilliseconds]);

    return (
        <StyledLoader $isShowing={props.isShowing}>
            {isLoaderHidden ? null : props.isShowing ? (
                <Lottie height={300} options={defaultLottieOptions(isBrandDefault)} width={300} />
            ) : (
                ''
            )}
        </StyledLoader>
    );
};
