import { BrowserRouter } from 'react-router';

import { Header } from 'src/components/header';

import { UserRouter } from './user-router';

export const AppRouter = () => (
    <BrowserRouter>
        <Header />
        <UserRouter />
    </BrowserRouter>
);
