import * as Sentry from '@sentry/react';
import branch, { BranchError, SessionData } from 'branch-sdk';
import { initializeApp } from 'firebase/app';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { store } from 'src/store';

import { getApplicationConfig } from 'src/configuration/config';

import packageJson from '../package.json';
import App from './App';
import './fonts.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

const { firebaseConfig, env, sentryConfig, branchKey } = getApplicationConfig();

const options = { no_journeys: true };
branch.init(branchKey, options, (err: BranchError, data: SessionData | null) => console.log(err, data));

export const firebaseApp = initializeApp(firebaseConfig);

if (sentryConfig && !!sentryConfig?.dsn) {
    Sentry.init({
        autoSessionTracking: true,
        dsn: sentryConfig.dsn,
        environment: env,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
            Sentry.feedbackIntegration({
                // Additional SDK configuration goes in here, for example:
            }),
        ],
        release: `${packageJson.name}@${packageJson.version}`,
        replaysOnErrorSampleRate: sentryConfig?.replaysOnErrorSampleRate ?? 1.0,
        replaysSessionSampleRate: sentryConfig?.replaysSessionSampleRate ?? 0.1,
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: sentryConfig?.tracesSampleRate ?? 0.1,
    });
}

if (import.meta.env.PROD && !import.meta.env.SENTRY_AUTH_TOKEN) console.log('SENTRY_AUTH_TOKEN not found');

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);
