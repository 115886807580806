import { Global } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { AppRouter } from 'src/router';

import setupAxiosInterceptors from 'src/configuration/axios-interceptor';
import { getApplicationConfig } from 'src/configuration/config';

import { useBranding } from 'src/hooks/useBranding.ts';

import { signOut } from 'src/store/user/actions.thunks';

import { globalStyles } from 'src/theme/global-styles';

import { TEXT_VARS } from './i18n/en';
import { GeneralError } from './screens/general-error';

const { theme } = getApplicationConfig();

setupAxiosInterceptors(async () => await signOut());

const brandTheme = createTheme(theme);

const App = () => {
    const theme = useBranding(brandTheme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Global styles={globalStyles} />
                <Sentry.ErrorBoundary fallback={<GeneralError errMsg={TEXT_VARS.ERRORS.UNEXPECTED_ERROR} />}>
                    <AppRouter />
                </Sentry.ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const AppWithProfiler = Sentry.withProfiler(App);

export default AppWithProfiler;
