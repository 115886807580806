import { useSelector } from 'react-redux';

import PrivateRoutes from 'src/router/user-router/private';
import PublicRoutes from 'src/router/user-router/public';

import { selectSignInState } from 'src/store/user/selectors';

export const UserRouter = () => {
    const isUserAuthenticated = useSelector(selectSignInState);

    return isUserAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};
