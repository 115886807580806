import { useCallback } from 'react';
import { useNavigate, useLocation, NavigateOptions } from 'react-router';

import { AppRoutes } from 'src/router/config';

export const useAppRoute = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useCallback(
        (url: AppRoutes | string, options?: NavigateOptions, query?: string) =>
            navigate(query ? `${url}?${query}` : url, {
                ...(options as object),
                state: { from: pathname },
            }),
        [pathname, navigate]
    );
};
