import { Checkbox, CheckboxProps, FormControlLabel, SxProps } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import WarningBlack from 'src/assets/img/warning_black_24dp.svg?react';

import { AccountComponent } from 'src/components/account/account-component.tsx';
import { AppButton } from 'src/components/button';
import { Charges } from 'src/components/charges';
import { ErrorPopupRedesign } from 'src/components/error-popup-redesign';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { PrivateAndSecure } from 'src/components/private-and-secure';
import { RepaymentSchedule } from 'src/components/repayment-schedule';
import { Title } from 'src/components/title';

import { BRANDS } from 'src/configuration/constants';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { CardComponent } from 'src/screens/confirmation/components/card-component';
import {
    StyledBorder,
    StyledCancelButton,
    StyledConfirmButton,
    StyledInfoText,
    StyledLinkButton,
    StyledPaymentCode,
    StyledPrivate,
    StyledSpan,
    StyledSubTitle,
    StyledTermsConditions,
    StyledTextBlock,
    StyledTitle,
    TextBody,
} from 'src/screens/confirmation/confirmation.styled';

import { selectSelectedBankAccount } from 'src/store/accounts/selectors';
import { setFirstBARender } from 'src/store/app/reducer';
import { selectIsPaymentCodeFlow, selectLinkFlow } from 'src/store/app/selectors';
import { selectSelectedCard } from 'src/store/cards/selectors';
import { selectAppMode, selectBrandName } from 'src/store/config/selectors';
import { cancelPayment, createPayment, preparePayment } from 'src/store/payment/actions.thunks';
import { FinancingOption } from 'src/store/payment/models';
import {
    selectCardAuthMode,
    selectFinancingOption,
    selectPreparePaymentResponse,
} from 'src/store/payment/selectors';
import { getCurrentCustomer, signOut } from 'src/store/user/actions.thunks';
import { selectPaymentLinkData, selectPrepayment, selectQueryParams } from 'src/store/user/selectors';

import { AppColors } from 'src/theme/app-colors';
import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { isCreditCardExpired } from 'src/utils/isCreditCardExpired';

const PAYMENT_SUCCESS_MSG_CALLBACK = 'paymentSuccess';
const FIREBASE_LOG_OUT = 'firebaseLogOut';
const ON_PAYMENT_COMPLETE_ACTION = 'onComplete';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';
const CANCEL_PAYMENT = 'cancelPaymentAction';

const sx: SxProps = {
    color: AppColors.primary,
    '&.Mui-checked': {
        color: AppColors.primary,
    },
};

export const CustomCheckbox = (props: CheckboxProps) => (
    <Checkbox color="default" name="This" sx={sx} {...props} />
);

export const Confirmation = () => {
    const h = useNavigate();
    const route = useAppRoute();
    const isMountedRef = useMountedRef();
    const dispatch = useDispatch();

    const [
        getCurrentUserAction,
        [isGetCurrentUserActionLoading],
        [currentCustomerThunkError, setCurrentCustomerThunkError],
    ] = useThunkAction(getCurrentCustomer);

    const [_preparePaymentAction, [isPreparePaymentLoading], [preparePaymentError, setPreparePaymentError]] =
        useThunkAction(preparePayment);

    const [_createPaymentAction, [isCreatePaymentLoading], [createPaymentError, setCreatePaymentError]] =
        useThunkAction(createPayment);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError, setCancelPaymentError]] =
        useThunkAction(cancelPayment);

    const creditCard = useSelector(selectSelectedCard);
    const bankAccount = useSelector(selectSelectedBankAccount);
    const preparePaymentResponse = useSelector(selectPreparePaymentResponse);
    const prepayment = useSelector(selectPrepayment);
    const queryParams = useSelector(selectQueryParams);
    const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
    const [creditCardErrorText, setCreditCardErrorText] = useState('');

    const cardAuthMode = useSelector(selectCardAuthMode);
    const paymentLinkData = useSelector(selectPaymentLinkData);
    const financingOption = useSelector(selectFinancingOption);
    const brandName = useSelector(selectBrandName);
    const appMode = useSelector(selectAppMode);
    const linkFlow = useSelector(selectLinkFlow);

    const isPaymentCodeFlow = useSelector(selectIsPaymentCodeFlow);
    const [_createPaymentErrorMsg, setCreatePaymentErrorMsg] = useState('');
    const [systemError, setSystemError] = useState<string>('');
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);
    const [isConsentChecked, setIsConsentChecked] = useState(prepayment?.requestAutoPayment);
    const [isHiccupDisplayed, setIsHiccupDisplayed] = useState(false);

    const selectedFinancingOption = useMemo((): FinancingOption | null => {
        if (!financingOption) return null;

        const _financingOption: FinancingOption = jwtDecode(financingOption);
        if (!_financingOption.bnplMerchantId) {
            return null;
        } else return _financingOption;
    }, [financingOption]);

    useEffect(() => {
        dispatch(setFirstBARender(false));
    }, [dispatch]);

    useEffect(() => {
        getCurrentUserAction().then(r => {
            if (!isMountedRef.current) return r;

            if (!r.success) {
                if (r.error.message.response && r.error.message.response?.status >= 500) {
                    setCurrentCustomerThunkError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }
            }
            return r;
        });
    }, [getCurrentUserAction, isMountedRef, setCurrentCustomerThunkError]);

    const isDirectPayment = useMemo(() => {
        if (!financingOption) {
            return true;
        }

        const decodedFinOption: FinancingOption = jwtDecode(financingOption);
        return !decodedFinOption.financingProductId;
    }, [financingOption]);

    useEffect(() => {
        if (preparePaymentResponse) return;

        if (!queryParams) {
            setSystemError('QueryParams unavailable, but expected');
            return;
        }

        if (!bankAccount) {
            setSystemError('Selected Bank Account unavailable, but expected');
            return;
        }

        _preparePaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                merchantOrderId: queryParams.orderId || prepayment?.merchantOrderId || '',
                bankAccountId: bankAccount.id,
                type: isDirectPayment ? 'SIMPLE' : 'FINANCING',
                ...(!isDirectPayment && { financingOption }),
            },
        }).then(r => {
            if (!isMountedRef.current) return;

            if (!r.success) {
                if (r.error.message.response && r.error.message.response?.status >= 500) {
                    setPreparePaymentError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }

                if (r.error.message.response && r.error.message.response?.status == 423) {
                    setPreparePaymentError(undefined);
                    setIsHiccupDisplayed(true);
                    return;
                }

                window.top?.postMessage(
                    {
                        onComplete: ON_PAYMENT_COMPLETE_ACTION,
                        isPaymentSuccess: false,
                        shopParams: {
                            amount: queryParams.amount,
                            currency: queryParams.currency,
                            orderId: queryParams.orderId,
                            description: queryParams.description,
                            backUrl: queryParams.backUrl,
                        },
                        paymentDetails: {
                            failureReason: r.error.message.toString(),
                        },
                    },
                    '*'
                );
            }
        });
    }, [
        _preparePaymentAction,
        bankAccount,
        financingOption,
        isDirectPayment,
        isMountedRef,
        preparePaymentResponse,
        prepayment?.merchantOrderId,
        queryParams,
        setPreparePaymentError,
    ]);

    useEffect(() => {
        setPreparePaymentError(undefined);
        setCreatePaymentError(undefined);
        setIsPaymentSuccess(false);
        setSystemError('');
        setCancelPaymentError(undefined);
    }, [setCreatePaymentError, setIsPaymentSuccess, setPreparePaymentError, setCancelPaymentError]);

    useEffect(() => {
        if (!creditCard && cardAuthMode !== 'NEVER' && cardAuthMode !== undefined) {
            route(AppRoutes.customerSelectYourCard);
        }
    }, [cardAuthMode, creditCard, route]);

    const handleCardChange = useCallback(() => {
        route(AppRoutes.customerSelectYourCard);
    }, [route]);

    const handleAccountChange = useCallback(() => {
        route(AppRoutes.customerSelectYourBankAccount);
    }, [route]);

    const handleContinueClick = useCallback(() => {
        if (!preparePaymentResponse) {
            return;
        }

        if (!queryParams) {
            setSystemError('QueryParams unavailable, but expected');
            return;
        }

        if (!bankAccount) {
            setSystemError('Selected Bank Account unavailable, but expected');
            return;
        }

        _createPaymentAction({
            payment: {
                ...(isConsentChecked !== null && { allowAutoPayment: isConsentChecked }),
                amount: preparePaymentResponse.amount,
                bankAccountId: bankAccount.id,
                creditCardId: creditCard?.id,
                currency: preparePaymentResponse.currency,
                description: prepayment?.description || queryParams.description,
                merchantOrderId: preparePaymentResponse.merchantOrderId,
                cardAuthMode: preparePaymentResponse.cardAuthMode,
                ...(paymentLinkData && { paymentToken: paymentLinkData.token }),
                ...(!isDirectPayment && { financingOption: financingOption }),
                paymentType: isDirectPayment ? 'SIMPLE' : 'FINANCING',
                ...(prepayment && { prePaymentId: prepayment.id }),
            },
        })
            .then(async r => {
                if (!isMountedRef.current) {
                    return;
                }
                if (!r.success) {
                    setCreatePaymentErrorMsg(r.error.message.toString());
                    if (r.error.message.response.status >= 500) {
                        setCreatePaymentError(TEXT_VARS.ERRORS.SERVER_ERROR);
                    }
                    window.top?.postMessage(
                        {
                            onComplete: ON_PAYMENT_COMPLETE_ACTION,
                            isPaymentSuccess: false,
                            errorMessage: r.error.message.toString(),
                            reasonCode: r.error.message.response.status,
                        },
                        '*'
                    );
                    return;
                }

                const payload = r.payload as {
                    customerId: string;
                    paymentId: string;
                    paymentToken: string;
                    shortId: string;
                };
                window.top?.postMessage(
                    {
                        onComplete: ON_PAYMENT_COMPLETE_ACTION,
                        isPaymentSuccess: true,
                        shopParams: {
                            amount: queryParams.amount,
                            currency: queryParams.currency,
                            orderId: queryParams.orderId,
                            description: queryParams.description,
                            backUrl: queryParams.backUrl,
                        },
                        paymentDetails: {
                            customerId: payload.customerId,
                            paymentId: payload.paymentId,
                            shortId: payload.shortId,
                        },
                        paymentToken: payload.paymentToken,
                    },
                    '*'
                );

                if (paymentLinkData) {
                    await signOut();
                    h(`/l/${paymentLinkData.token}`);
                    return;
                }

                if (isPaymentCodeFlow || appMode === 'webApp') {
                    route(AppRoutes.downloadApp);
                    return;
                }

                setIsPaymentSuccess(true);
            })
            .then(() => sessionStorage.clear());
    }, [
        preparePaymentResponse,
        queryParams,
        bankAccount,
        _createPaymentAction,
        creditCard?.id,
        paymentLinkData,
        financingOption,
        isDirectPayment,
        isMountedRef,
        isPaymentCodeFlow,
        appMode,
        setCreatePaymentError,
        h,
        route,
        prepayment,
        isConsentChecked,
    ]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const cancelPaymentButtonClick = useCallback(() => {
        if (!preparePaymentResponse) {
            return;
        }

        if (!queryParams) {
            setSystemError('QueryParams unavailable, but expected');
            return;
        }

        if (!bankAccount) {
            setSystemError('Selected Bank Account unavailable, but expected');
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: preparePaymentResponse.amount,
                bankAccountId: bankAccount.id,
                creditCardId: creditCard?.id,
                currency: preparePaymentResponse.currency,
                description: queryParams.description,
                merchantOrderId: preparePaymentResponse.merchantOrderId,
                cardAuthMode: preparePaymentResponse.cardAuthMode,
                ...(paymentLinkData && { paymentToken: paymentLinkData.token }),
            },
        }).then(r => {
            if (!r.success) return;
            setIsCancelPaymentSuccess(true);
        });
    }, [
        bankAccount,
        cancelPaymentAction,
        creditCard?.id,
        paymentLinkData,
        preparePaymentResponse,
        queryParams,
    ]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
        setPreparePaymentError(undefined);
        setCreatePaymentError(undefined);
    }, [setPreparePaymentError, setCreatePaymentError]);

    const handleSuccessButtonClick = useCallback(async () => {
        if (!queryParams) {
            setSystemError('QueryParams unavailable, but expected');
            return;
        }

        if (paymentLinkData) {
            await signOut();
            h(`/l/${paymentLinkData.token}`);
            return;
        }

        window.top?.postMessage(
            { paymentMessage: PAYMENT_SUCCESS_MSG_CALLBACK, backUrl: queryParams.backUrl },
            '*'
        );
    }, [h, paymentLinkData, queryParams]);

    window.addEventListener('message', async function (event) {
        if (event.data.signOut === FIREBASE_LOG_OUT) {
            await signOut();
        }
    });

    const cardAuthModeText = useMemo(() => {
        if (cardAuthMode === 'NEVER') {
            return TEXT_VARS.COMMON_TEXT.CONFIRMATION_NEVER;
        }
        if (cardAuthMode === 'CHARGE_AS_FALLBACK') {
            return TEXT_VARS.COMMON_TEXT.CONFIRMATION_2;
        } else return TEXT_VARS.COMMON_TEXT.CONFIRMATION_3;
    }, [cardAuthMode]);

    const isErrorDisplayed =
        !!preparePaymentError ||
        !!createPaymentError ||
        !!currentCustomerThunkError ||
        !!systemError ||
        !!cancelPaymentError;

    useEffect(() => {
        if (cardAuthMode === 'NEVER' || !creditCard) return;

        if (isCreditCardExpired(creditCard)) {
            setCreditCardErrorText(TEXT_VARS.ERRORS.THIS_CARD_EXPIRED);
        }
    }, [cardAuthMode, creditCard]);

    const isContinueButtonDisabled = useMemo(() => {
        return !!creditCardErrorText;
    }, [creditCardErrorText]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await cancelPaymentButtonClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [cancelPaymentButtonClick]
    );
    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    const isBrandChargee = useMemo(() => {
        return brandName === BRANDS.CHARGEE;
    }, [brandName]);

    const handleConsentCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsConsentChecked(event.target.checked);
    }, []);

    const textError =
        preparePaymentError || createPaymentError || cancelPaymentError || currentCustomerThunkError;

    const isConsentFlow = linkFlow === 'CONSENT_LINK';

    return (
        <>
            <StyledPageWithPaddingContainer
                $isPointerEventsDisabled={isErrorDisplayed || isPaymentSuccess || isCancelPopupDisplayed}
            >
                <Loader
                    isShowing={
                        isGetCurrentUserActionLoading ||
                        isPreparePaymentLoading ||
                        isCreatePaymentLoading ||
                        isCancelPaymentLoading
                    }
                />
                <StyledTitle>
                    <Title isChargee={isBrandChargee} title={TEXT_VARS.TITLE.REVIEW_YOUR_PURCHASE} />
                </StyledTitle>
                {isBrandChargee && (
                    <StyledPaymentCode>
                        <span>Payment code:</span>
                        <span>{prepayment?.shortCode}</span>
                    </StyledPaymentCode>
                )}
                <TextBody>
                    {!isBrandChargee && <StyledInfoText>{cardAuthModeText}</StyledInfoText>}
                    {bankAccount && preparePaymentResponse && (
                        <AccountComponent
                            bankAccount={bankAccount}
                            errorMessage={_createPaymentErrorMsg}
                            handleAccountChange={handleAccountChange}
                            totalAmount={preparePaymentResponse.totalAmount}
                        />
                    )}
                    {creditCard && cardAuthMode !== 'NEVER' && (
                        <CardComponent
                            creditCard={creditCard}
                            errorMessage={creditCardErrorText}
                            handleCardChange={handleCardChange}
                        />
                    )}
                    {selectedFinancingOption && (
                        <StyledSubTitle>
                            <span>Charges today</span>
                            <span>$0.00</span>
                        </StyledSubTitle>
                    )}
                    <StyledBorder />

                    {financingOption && <RepaymentSchedule financingOption={financingOption} />}

                    {preparePaymentResponse && (
                        <Charges
                            financingOption={selectedFinancingOption}
                            fontSize="14px"
                            isBrandChargee={isBrandChargee}
                            paymentResponse={preparePaymentResponse}
                        />
                    )}
                    {isConsentFlow && (
                        <StyledTextBlock>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={isConsentChecked}
                                        name="checked"
                                        onChange={handleConsentCheckboxChange}
                                    />
                                }
                                label={TEXT_VARS.COMMON_TEXT.I_AGREE_TO_ALLOW}
                            />
                        </StyledTextBlock>
                    )}

                    <StyledTermsConditions>
                        <span className="terms-and-conditions">
                            <StyledSpan>{TEXT_VARS.COMMON_TEXT.BY_CONTINUING_YOU_AGREE}</StyledSpan>
                            <StyledLinkButton href="https://billmybank.com/terms-of-use/" target="_blank">
                                {TEXT_VARS.COMMON_TEXT.TERM_AND_CONDITIONS}
                            </StyledLinkButton>
                            .
                        </span>
                    </StyledTermsConditions>
                </TextBody>

                <StyledConfirmButton>
                    <AppButton
                        appButtonType="Continue"
                        disabled={isContinueButtonDisabled}
                        onClick={handleContinueClick}
                    >
                        {isCreatePaymentLoading
                            ? TEXT_VARS.BUTTON.PROCESSING_TRANSACTION
                            : TEXT_VARS.BUTTON.PROCESS_TRANSACTION}
                    </AppButton>
                </StyledConfirmButton>
                <StyledCancelButton>
                    <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                        {TEXT_VARS.BUTTON.CANCEL}
                    </AppButton>
                </StyledCancelButton>
                {isBrandChargee && (
                    <StyledPrivate>
                        <PrivateAndSecure />
                    </StyledPrivate>
                )}
            </StyledPageWithPaddingContainer>
            {isErrorDisplayed && (
                <InfoActionLayout
                    bodyText={textError}
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    isCancelPaymentSuccess={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={cancelPaymentButtonClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    type="error"
                />
            )}
            {isPaymentSuccess && (
                <InfoActionLayout
                    bodyText={TEXT_VARS.COMMON_TEXT.YOUR_PURCHASE_HAS_BEEN}
                    buttonTitle={TEXT_VARS.BUTTON.LETS_GO}
                    onClick={handleSuccessButtonClick}
                    titleText={TEXT_VARS.COMMON_TEXT.AWESOME}
                    type="success"
                />
            )}
            {isHiccupDisplayed && (
                <ErrorPopupRedesign
                    bodyText={TEXT_VARS.COMMON_TEXT.WE_UNABLE_PROCESS_PAYMENT}
                    Icon={WarningBlack}
                    labelText={TEXT_VARS.COMMON_TEXT.OOPS_HICCUP}
                />
            )}
        </>
    );
};
