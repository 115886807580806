import currency from 'currency.js';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Loader } from 'src/components/loader';

import { useAppRoute } from 'src/hooks/useAppRoute.ts';
import { useGoApp } from 'src/hooks/useGoApp.ts';
import { useThunkAction } from 'src/hooks/useThunkAction.ts';

import { AppRoutes } from 'src/router/config.ts';

import {
    StyledAmount,
    StyledTextBlock,
    StyledSummary,
    StyledTitleWrapper,
    StyledTextBlocksWrapper,
} from 'src/screens/change-request/change-request.styled.ts';
import SignIn from 'src/screens/sign-in';

import { setChangeFlow, setCustomerTypeForChange, setVirtualCustomerId } from 'src/store/app/reducer.ts';
import { ChangeRequestDTO } from 'src/store/change-request/models.ts';
import { UncompletedCRDTO } from 'src/store/customer/models.ts';
import { getCustomerChange } from 'src/store/user/actions.thunks.ts';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components.ts';

const getSum = (changeRequests: ChangeRequestDTO[] | undefined, key: 'chargeAmount' | 'totalAmount') =>
    changeRequests?.reduce((acc, changeRequest) => acc + changeRequest[key], 0);

const getAmounts = (changeRequests: ChangeRequestDTO[] | undefined) => ({
    amountPaid: getSum(changeRequests, 'chargeAmount'),
    totalAmount: getSum(changeRequests, 'totalAmount'),
});

export const ChangeRequest = () => {
    const route = useAppRoute();

    const dispatch = useDispatch();

    const { virtualCustomerId } = useParams<{ virtualCustomerId: string }>();
    const [data, setData] = useState<UncompletedCRDTO | undefined>(undefined);

    const [isGetWidgetSettingLoading] = useGoApp();

    const [getCustomerChangeAction, [isGetCustomerChangeLoading]] = useThunkAction(getCustomerChange);

    const { amountPaid, totalAmount } = useMemo(
        () => getAmounts(data?.changeRequests.content),
        [data?.changeRequests.content]
    );

    useEffect(() => {
        dispatch(setChangeFlow(true));
        if (virtualCustomerId) {
            dispatch(setVirtualCustomerId(virtualCustomerId));
            getCustomerChangeAction({ customerId: virtualCustomerId }).then(r => {
                if (!r.success) return;

                const payload = r.payload as UncompletedCRDTO;
                dispatch(setCustomerTypeForChange(payload.customerType));
                if (payload.totalAmount === 0) {
                    route(AppRoutes.walletEmpty);
                    return;
                }
                setData(payload);
            });
        }
    }, [dispatch, getCustomerChangeAction, route, virtualCustomerId]);

    return (
        <StyledPageWithPaddingContainer>
            <Loader isShowing={isGetCustomerChangeLoading || isGetWidgetSettingLoading} />

            <StyledTitleWrapper>
                <span>Continue to account to claim</span>
                <span>
                    <span>your</span>
                    {data?.totalAmount && (
                        <StyledAmount>{`${currency(data.totalAmount).format()} change`}</StyledAmount>
                    )}
                </span>
            </StyledTitleWrapper>
            <StyledSummary>Summary:</StyledSummary>
            <StyledTextBlocksWrapper>
                {amountPaid && (
                    <StyledTextBlock>
                        <span>Amount Paid:</span>
                        <span>{currency(amountPaid).format()}</span>
                    </StyledTextBlock>
                )}
                {totalAmount && (
                    <StyledTextBlock>
                        <span>Total Cost:</span>
                        <span>{currency(totalAmount).format()}</span>
                    </StyledTextBlock>
                )}
                {data && data.totalAmount && (
                    <StyledTextBlock>
                        <span>Your Change:</span>
                        <span>{currency(data.totalAmount).format()}</span>
                    </StyledTextBlock>
                )}
            </StyledTextBlocksWrapper>
            <SignIn />
        </StyledPageWithPaddingContainer>
    );
};
