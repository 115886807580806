import { styled } from '@mui/material/styles';

export const StyledTitleWrapper = styled('div')`
    margin: 40px 0;
    align-self: center;
    width: fit-content;

    .firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-password.firebaseui-id-idp-button {
        max-width: 317px;
        width: 317px;
        height: 60px;
        background-color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main} !important;
        border-radius: 100px;
        padding-left: 30px;
        box-shadow: none;
        font-size: 21px;
        :hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }
    }

    .firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-google.firebaseui-id-idp-button {
        max-width: 317px;
        width: 317px;
        height: 60px;
        background-color: #ffffff !important;
        border-radius: 100px;
        padding-left: 30px;
        span {
            color: black !important;
        }
        border: #a9a9ad 1px solid;
        box-shadow: none;
        font-size: 21px;
        :hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }
    }

    .firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-generic.firebaseui-id-idp-button {
        max-width: 317px;
        width: 317px;
        height: 60px;
        background-color: #000000 !important;
        border-radius: 100px;
        padding-left: 30px;
        box-shadow: none;
        font-size: 21px;

        :hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }
    }

    .firebaseui-idp-text {
        font-size: 21px !important;
        font-family: 'Helvetica Neue', serif !important;
    }

    span.firebaseui-idp-icon-wrapper {
        img {
            width: 27px;
            height: 27px;
        }
    }
    .firebaseui-idp-icon {
    }

    .firebaseui-idp-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .firebaseui-card-header {
        border: none !important;
    }

    .firebaseui-form-actions {
        display: flex !important;
        flex-direction: column-reverse !important;
        gap: 10px !important;
    }

    .firebaseui-title {
        text-align: center !important;
        margin-top: 35px;
    }

    .firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary {
        height: 60px;
        width: 317px;
        background-color: #ffffff !important;
        border-radius: 100px;
        color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main} !important;
        border: #a9a9ad 1px solid;
        font-size: 21px;
        text-transform: capitalize;
        font-family: 'Helvetica Neue', serif, Arial, sans-serif;
        margin: 0;

        :hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }
    }

    .firebaseui-id-submit.firebaseui-button.mdl-button.mdl-js-button.mdl-button--raised.mdl-button--colored {
        height: 60px;
        background-color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main} !important;
        border-radius: 100px;
        font-size: 21px;
        text-transform: capitalize;
        font-family: 'Helvetica Neue', serif, Arial, sans-serif;
        box-shadow: none;
        margin-left: 0;

        :hover {
            background-image: linear-gradient(#0000, rgb(0 0 0/20%));
        }
    }

    .firebaseui-card-header {
        margin-top: -60px;
    }

    .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-sign-in {
        box-shadow: none;
    }

    .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in {
        box-shadow: none;
    }

    .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-up {
        box-shadow: none;
    }

    .firebaseui-tos-list.firebaseui-tos {
        display: flex;
        justify-content: center;
    }

    .mdl-card.mdl-shadow--2dp.firebaseui-container.firebaseui-id-page-password-sign-in {
        width: 317px;
    }

    .firebaseui-label::after {
        background-color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main} !important;
    }

    .mdl-textfield__label {
        color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main} !important;
    }
`;
