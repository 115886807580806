import { createSelector } from 'reselect';

import { ApplicationState } from 'src/store/root-reducer.models';

export const getAppState = (state: ApplicationState) => state.app;

export const selectIsPaymentCodeFlow = createSelector(
    getAppState,
    ({ isPaymentCodeFlow }) => isPaymentCodeFlow
);

export const selectPaymentCode = createSelector(getAppState, ({ paymentCode }) => paymentCode);
export const selectWelcomeMode = createSelector(getAppState, ({ isWelcomeFlow }) => isWelcomeFlow);
export const selectLinkFlow = createSelector(getAppState, ({ linkFlow }) => linkFlow);
export const selectFirstRenderBankAcc = createSelector(
    getAppState,
    ({ isFirstBankAccRender }) => isFirstBankAccRender
);
export const selectChangeFlow = createSelector(getAppState, ({ isChangeFlow }) => isChangeFlow);
export const selectVirtualCustomerId = createSelector(
    getAppState,
    ({ virtualCustomerId }) => virtualCustomerId
);
export const selectCustomerTypeChangeFlow = createSelector(
    getAppState,
    ({ customerTypeChangeFlow }) => customerTypeChangeFlow
);

export const selectBranding = createSelector(getAppState, ({ branding }) => branding);
