import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledAppInputComponent = styled('div')`
    .app-input-title {
        margin-bottom: ${units.p8}px;
        ${AppTypography.appInputTitle};
    }
`;

export const AppInputTextField = styled(TextField)`
    width: 301px;

    .MuiInputLabel-root {
        color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main};
    }

    .MuiInputLabel-root.Mui-focused {
        color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main};
    }

    .MuiInput-root {
        :after {
            border-bottom-color: ${({ theme }) =>
                theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main};
        }
    }
`;
