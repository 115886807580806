import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppButton } from 'src/components/button';
import FirebaseSignIn from 'src/components/firebase-sign-in';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { Title } from 'src/components/title';

import { BRANDS } from 'src/configuration/constants';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { StyledButtonSection, StyledIframe } from 'src/screens/sign-in/sign-in.styled';

import { saveSelectedBankAccount } from 'src/store/accounts/reducer';
import { selectChangeFlow, selectLinkFlow, selectWelcomeMode } from 'src/store/app/selectors';
import { saveSelectedCard } from 'src/store/cards/reducer';
import { selectBrandName } from 'src/store/config/selectors';
import { cancelPayment } from 'src/store/payment/actions.thunks';
import {
    createOrUpdateCustomer,
    generateJWTToken,
    getCurrentCustomer,
    sendUserAgreements,
    signOut,
} from 'src/store/user/actions.thunks';
import { saveIsCustomerNew, saveMerchantIdToken } from 'src/store/user/reducer';
import {
    selectAcceptedAgreementsStatus,
    selectCustomer,
    selectIsCustomerNew,
    selectIsTermsAndConditionsDisplayed,
    selectJWTToken,
    selectPrepayment,
    selectQueryParams,
    selectSignInState,
    selectSignInUser,
    selectUserAgreements,
} from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

const SIGN_IN_CANCEL_CALLBACK = 'cancelCallback';
const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

export const SignIn: React.FC = () => {
    const route = useAppRoute();
    const dispatch = useDispatch();

    const queryParams = useSelector(selectQueryParams);
    const isUserAuthenticated = useSelector(selectSignInState);
    const customer = useSelector(selectCustomer);
    const isWelcomeFlow = useSelector(selectWelcomeMode);
    const isCustomerNew = useSelector(selectIsCustomerNew);
    const userAgreements = useSelector(selectUserAgreements);
    const isTermsAndConditionsDisplayed = useSelector(selectIsTermsAndConditionsDisplayed);
    const isAcceptedAgreementsStatus = useSelector(selectAcceptedAgreementsStatus);
    const brandName = useSelector(selectBrandName);
    const user = useSelector(selectSignInUser);
    const generatedJWTToken = useSelector(selectJWTToken);
    const prepayment = useSelector(selectPrepayment);
    const linkFlow = useSelector(selectLinkFlow);
    const [isTokenGenerated, setIsTokenGenerated] = useState(false);
    const [isUserAuth, setIsUserAuth] = useState(false);
    const isChangeFlow = useSelector(selectChangeFlow);

    const isAgreementsAccepted = useMemo(() => {
        return !isTermsAndConditionsDisplayed || isAcceptedAgreementsStatus;
    }, [isAcceptedAgreementsStatus, isTermsAndConditionsDisplayed]);

    const [cancelPaymentAction] = useThunkAction(cancelPayment);

    const [getCurrentCustomerAction, [isLoadingGetCurrentCustomer]] = useThunkAction(getCurrentCustomer);

    const [createOrUpdateCustomerAction, [isCreateOrUpdateCustomerLoading], [updatingError]] =
        useThunkAction(createOrUpdateCustomer);

    const [
        sendAgreementAcceptationAction,
        [isLoadingSendAgreementAcceptation],
        [sendAgreementAcceptationError],
    ] = useThunkAction(sendUserAgreements);

    const [generateJWTTokenAction, [isGenerateJWTTokenLoading], [generateJWTTokenError]] =
        useThunkAction(generateJWTToken);

    useEffect(() => {
        if (!user) {
            return;
        }

        if (isTokenGenerated) {
            return;
        }

        const _generateJWTTokenAction = async () => {
            return await generateJWTTokenAction({
                ...(queryParams && {
                    apiKey: queryParams.apiKey,
                    merchantId: queryParams.merchantId,
                    apiToken: queryParams.apiToken,
                }),
                paymentLinkToken: prepayment?.shortCode,
            });
        };

        _generateJWTTokenAction()
            .then(r => {
                if (!r.success) return;
                const payload = r.payload as { idToken: string };
                setIsTokenGenerated(true);
                dispatch(saveMerchantIdToken(payload.idToken));
            })
            .then(() => {
                getCurrentCustomerAction();
            });
    }, [
        isTokenGenerated,
        generateJWTTokenAction,
        queryParams,
        user,
        prepayment,
        dispatch,
        getCurrentCustomerAction,
        generatedJWTToken,
    ]);

    const isQueryError = useMemo(() => {
        return (
            !(queryParams && queryParams.amount && queryParams.apiToken) &&
            (!linkFlow || linkFlow != 'CONSENT_LINK') &&
            !isChangeFlow
        );
    }, [linkFlow, queryParams, isChangeFlow]);

    const handleInfoLayoutActionClick = useCallback(() => {
        route(AppRoutes.buyNowPayLater);
    }, [route]);

    const handleCancelClick = useCallback(() => {
        if (!queryParams) {
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                description: queryParams.description,
                merchantOrderId: queryParams.orderId,
            },
        }).finally(() => {
            window.top?.postMessage({ cancelAction: SIGN_IN_CANCEL_CALLBACK }, '*');
        });
    }, [cancelPaymentAction, queryParams]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await handleCancelClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [handleCancelClick]
    );

    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    useEffect(() => {
        if (isUserAuthenticated && isAgreementsAccepted) {
            const _getCurrentCustomerAction = async () => {
                await getCurrentCustomerAction().then(async r => {
                    if (
                        !r.success &&
                        (r.error.message.response?.status === 404 || r.error.message.response?.status === 400)
                    ) {
                        dispatch(saveIsCustomerNew(true));
                        let isCustomerRouted = false;
                        if (
                            queryParams?.firstName &&
                            queryParams?.lastName &&
                            queryParams?.email &&
                            queryParams?.phone
                        ) {
                            await createOrUpdateCustomerAction({
                                name: queryParams.firstName,
                                lastName: queryParams.lastName,
                                email: queryParams.email,
                                phoneNumber: queryParams.phone,
                            }).then(r => {
                                if (!r.success) {
                                    return;
                                }
                                isCustomerRouted = true;
                                route(AppRoutes.customerSelectYourBankAccount);
                                return;
                            });
                        }
                        if (!isCustomerRouted) {
                            route(AppRoutes.customerEnterBasicInfo);
                            return;
                        }
                    }

                    if (!r.success) {
                        return;
                    }

                    dispatch(saveIsCustomerNew(false));
                });
            };
            _getCurrentCustomerAction().then(() => {
                setIsUserAuth(true);
            });
        }
    }, [
        createOrUpdateCustomerAction,
        dispatch,
        getCurrentCustomerAction,
        isUserAuthenticated,
        queryParams,
        route,
        isAgreementsAccepted,
    ]);

    useEffect(() => {
        if (!isTokenGenerated || !isUserAuth) {
            return;
        }

        if (isGenerateJWTTokenLoading) {
            return;
        }

        if (!customer || isCustomerNew) {
            return;
        }

        if (!isAgreementsAccepted) {
            return;
        }

        if (!generatedJWTToken) {
            return;
        }

        const isCustomerDefaultAccount = customer.defaultBankAccount;
        const isCustomerDefaultCard = customer.defaultCreditCard;

        if (isCustomerDefaultAccount && isCustomerDefaultCard) {
            dispatch(saveSelectedBankAccount(customer.defaultBankAccount!));
            dispatch(saveSelectedCard(customer.defaultCreditCard!));
        }

        if (isCustomerDefaultAccount) {
            dispatch(saveSelectedBankAccount(customer.defaultBankAccount!));
        }

        if (customer.phoneNumber && customer.defaultBankAccount && linkFlow != 'CONSENT_LINK') {
            isWelcomeFlow ? route(AppRoutes.customerReFinancing) : route(AppRoutes.customerConfirmation);
            return;
        } else {
            route(AppRoutes.customerEnterBasicInfo);
            return;
        }
    }, [
        isTokenGenerated,
        isUserAuth,
        isAgreementsAccepted,
        customer,
        dispatch,
        isWelcomeFlow,
        route,
        isCustomerNew,
        generatedJWTToken,
        linkFlow,
        isGenerateJWTTokenLoading,
    ]);

    const errorMessage = useMemo(() => {
        return (
            updatingError || generateJWTTokenError || TEXT_VARS.ERRORS.NO_API_TOKEN_OR_NO_AMOUNT_WAS_PROVIDED
        );
    }, [updatingError, generateJWTTokenError]);

    const isBrandChargee = useMemo(() => {
        return brandName === BRANDS.CHARGEE;
    }, [brandName]);

    const handleAcceptButtonClick = useCallback(() => {
        userAgreements &&
            sendAgreementAcceptationAction({ ...userAgreements, agreedAt: new Date().getTime() });
    }, [sendAgreementAcceptationAction, userAgreements]);

    const handleCancelButtonClick = useCallback(() => {
        signOut();
    }, []);

    return (
        <>
            <Loader isShowing={isLoadingGetCurrentCustomer || isCreateOrUpdateCustomerLoading} />
            <StyledPageWithPaddingContainer>
                <Loader isShowing={isLoadingSendAgreementAcceptation || isGenerateJWTTokenLoading} />
                {!isQueryError && !isUserAuthenticated && <FirebaseSignIn />}
                {isUserAuthenticated && isTermsAndConditionsDisplayed && (
                    <>
                        <Title isChargee={isBrandChargee} title={TEXT_VARS.TITLE.TERMS_AND_CONDITIONS} />
                        <StyledIframe src={userAgreements?.docUrl} />
                        <StyledButtonSection>
                            <AppButton appButtonType="TermsConditions" onClick={handleAcceptButtonClick}>
                                {TEXT_VARS.BUTTON.I_AGREE}
                            </AppButton>
                            <AppButton
                                appButtonType="TermsConditionsCancel"
                                onClick={handleCancelButtonClick}
                            >
                                {TEXT_VARS.BUTTON.CANCEL_AND_LOGOUT}
                            </AppButton>
                        </StyledButtonSection>
                    </>
                )}
            </StyledPageWithPaddingContainer>
            {(isQueryError || updatingError || sendAgreementAcceptationError || generateJWTTokenError) && (
                <InfoActionLayout
                    bodyText={sendAgreementAcceptationError || errorMessage}
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </>
    );
};

export default SignIn;
