import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router';

import { AppRoutes } from 'src/router/config';

import { AddBankAccounts } from 'src/screens/add-bank-accounts';
import { BuyNowPayLater } from 'src/screens/buy-now-pay-later';
import { ChangeConfirmation } from 'src/screens/change-confirmation';
import { Confirmation } from 'src/screens/confirmation';
import { ConsentConfirmation } from 'src/screens/consent-confirmation';
import { EnterBasicInfo } from 'src/screens/enter-basic-info';
import { ErrorPageFiserv } from 'src/screens/error-page-fiserv';
import { GeneralError } from 'src/screens/general-error';
import { GetTheApp } from 'src/screens/get-the-app';
import { ReFinancing } from 'src/screens/re-financing';
import { SelectYourBankAccount } from 'src/screens/select-your-bank-account';
import { SelectYourCard } from 'src/screens/select-your-card';
import SignIn from 'src/screens/sign-in';
import { TransactionExpired } from 'src/screens/transaction-expired';

import {
    selectAcceptedAgreementsStatus,
    selectIsTermsAndConditionsDisplayed,
} from 'src/store/user/selectors';

const PrivateRoutes = () => {
    const isTermsAndConditionsDisplayed = useSelector(selectIsTermsAndConditionsDisplayed);
    const isAcceptedAgreementsStatus = useSelector(selectAcceptedAgreementsStatus);

    return isTermsAndConditionsDisplayed && !isAcceptedAgreementsStatus ? (
        <Routes>
            <Route element={<SignIn />} key={AppRoutes.customerSignIn} path={AppRoutes.customerSignIn} />
            <Route element={<GeneralError />} key={AppRoutes.generalError} path={AppRoutes.generalError} />
            <Route
                element={<Navigate replace to={AppRoutes.customerSignIn} />}
                key={AppRoutes.customerSignIn}
                path="*"
            />
        </Routes>
    ) : (
        <Routes>
            <Route
                element={<EnterBasicInfo />}
                key={AppRoutes.customerEnterBasicInfo}
                path={AppRoutes.customerEnterBasicInfo}
            />
            <Route
                element={<Confirmation />}
                key={AppRoutes.customerConfirmation}
                path={AppRoutes.customerConfirmation}
            />
            <Route
                element={<ChangeConfirmation />}
                key={AppRoutes.changeConfirmation}
                path={AppRoutes.changeConfirmation}
            />
            <Route
                element={<SelectYourBankAccount />}
                key={AppRoutes.customerSelectYourBankAccount}
                path={AppRoutes.customerSelectYourBankAccount}
            />
            <Route
                element={<SelectYourCard />}
                key={AppRoutes.customerSelectYourCard}
                path={AppRoutes.customerSelectYourCard}
            />
            <Route
                element={<BuyNowPayLater />}
                key={AppRoutes.buyNowPayLater}
                path={AppRoutes.buyNowPayLater}
            />
            <Route
                element={<ReFinancing />}
                key={AppRoutes.customerReFinancing}
                path={AppRoutes.customerReFinancing}
            />
            <Route element={<GetTheApp />} key={AppRoutes.downloadApp} path={AppRoutes.downloadApp} />
            <Route element={<AddBankAccounts />} key={AppRoutes.addAccounts} path={AppRoutes.addAccounts} />
            <Route element={<ErrorPageFiserv />} key="/error-fiserv" path="/error-fiserv" />
            <Route element={<SignIn />} key={AppRoutes.customerSignIn} path={AppRoutes.customerSignIn} />
            <Route element={<GeneralError />} key={AppRoutes.generalError} path={AppRoutes.generalError} />
            <Route
                element={<ConsentConfirmation />}
                key={AppRoutes.consentConfirmation}
                path={AppRoutes.consentConfirmation}
            />

            <Route
                element={<TransactionExpired />}
                key={AppRoutes.transactionExpired}
                path={AppRoutes.transactionExpired}
            />

            <Route
                element={<Navigate replace to={AppRoutes.customerEnterBasicInfo} />}
                key={AppRoutes.customerEnterBasicInfo}
                path="*"
            />
        </Routes>
    );
};

export default PrivateRoutes;
