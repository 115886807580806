import { styled } from '@mui/material/styles';

import { AppColors } from 'src/theme/app-colors';
import { units } from 'src/theme/units';

export const StyledStepBar = styled('div')<{ step: number }>`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 302px;

    .left-point {
        z-index: 99;
        min-width: ${units.p26}px;
        height: ${units.p25}px;
        background-color: ${({ theme }) =>
            theme.brandTheme?.brandProperties?.primaryColor || theme.palette.primary.main};
        border-radius: 50%;
    }

    .middle-point {
        z-index: 97;
        min-width: ${units.p26}px;
        height: ${units.p25}px;
        border-radius: 50%;

        background-color: ${props => {
            switch (props.step) {
                case 1:
                    return AppColors.ellipseGrey;
                case 2:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                case 3:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                default:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
            }
        }};
    }

    .right-point {
        z-index: 95;
        min-width: ${units.p26}px;
        height: ${units.p25}px;
        border-radius: 50%;

        background-color: ${props => {
            switch (props.step) {
                case 1:
                    return AppColors.ellipseGrey;
                case 2:
                    return AppColors.ellipseGrey;
                case 3:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                default:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
            }
        }};
    }

    .left-line {
        z-index: 98;
        width: 100%;
        height: ${units.p12}px;
        margin: 0 -5px;
        align-self: center;

        background-color: ${props => {
            switch (props.step) {
                case 1:
                    return AppColors.ellipseGrey;
                case 2:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                case 3:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                default:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
            }
        }};
    }

    .right-line {
        z-index: 96;
        width: 100%;
        height: ${units.p12}px;
        margin: 0 -5px;
        align-self: center;

        background-color: ${props => {
            switch (props.step) {
                case 1:
                    return AppColors.ellipseGrey;
                case 2:
                    return AppColors.ellipseGrey;
                case 3:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
                default:
                    return (
                        props.theme.brandTheme?.brandProperties?.primaryColor ||
                        props.theme.palette.primary.main
                    );
            }
        }};
    }
`;
