import axios from 'axios';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { BackButton } from 'src/components/back-button';
import { BankAccountComponent } from 'src/components/bank-account';
import { AppButton } from 'src/components/button';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { Title } from 'src/components/title';

import { BRANDS } from 'src/configuration/constants';
import { ENDPOINT } from 'src/configuration/endpoints';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { StyledBodySection, StyledInfoText } from 'src/screens/add-bank-accounts/add-bank-accounts.styled';
import { StyledBorderBetweenAccounts } from 'src/screens/select-your-bank-account/select-your-bank-account.styled';

import {
    addBankAccount,
    checkAccountUpdateCompleted,
    getIdpBankAccountsThunk,
} from 'src/store/accounts/actions.thunks';
import { BankAccountInfoDTO } from 'src/store/accounts/models';
import { selectFiLoginAccounts } from 'src/store/accounts/selectors';
import { setFirstBARender } from 'src/store/app/reducer';
import { selectPaymentCode } from 'src/store/app/selectors';
import { selectBrandName } from 'src/store/config/selectors';
import { PrePaymentDTO } from 'src/store/user/models';
import { selectCustomerId, selectFiservToken } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

type PrepaymentByIdResponse = { data: PrePaymentDTO };

export const AddBankAccounts: React.FC = () => {
    const route = useAppRoute();
    const location = useLocation();
    const dispatch = useDispatch();

    const brandName = useSelector(selectBrandName);
    const customerId = useSelector(selectCustomerId);
    const fiLoginAccounts = useSelector(selectFiLoginAccounts);
    const fiservToken = useSelector(selectFiservToken);
    const paymentCode = useSelector(selectPaymentCode);

    const [selectedAccounts, setSelectedAccounts] = useState<BankAccountInfoDTO[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [
        checkAccountUpdateCompletedAction,
        [isCheckAccountUpdateCompletedLoading],
        [checkAccountUpdateCompletedError],
    ] = useThunkAction(checkAccountUpdateCompleted);

    const [getIdpBankAccountsAction, [isGetIdpBankAccountsLoading], [getIdpBankAccountsError]] =
        useThunkAction(getIdpBankAccountsThunk);

    const [addBankAccountAction, [isAddBankAccountLoading], [addBankAccountError]] =
        useThunkAction(addBankAccount);

    const isBrandChargee = useMemo(() => {
        return brandName === BRANDS.CHARGEE;
    }, [brandName]);

    const BankAccounts = useCallback(() => {
        const handleSelectBankAccount = (bankAccount: BankAccountInfoDTO) => {
            setSelectedAccounts((prev: BankAccountInfoDTO[]) => {
                const isAccountAdded = !!prev.find(acc => acc.id.guid === bankAccount.id.guid);
                return isAccountAdded
                    ? prev.filter(acc => acc.id.guid !== bankAccount.id.guid)
                    : [...prev, bankAccount];
            });
        };

        return (
            <>
                {fiLoginAccounts &&
                    fiLoginAccounts.map((acc, index) => (
                        <Fragment key={acc.id.guid}>
                            <BankAccountComponent
                                bankAccount={acc}
                                isRadioButtonChecked={
                                    !!selectedAccounts.find(account => account.id.guid === acc.id.guid)
                                }
                                onClick={handleSelectBankAccount}
                            />
                            {fiLoginAccounts.length !== index + 1 && <StyledBorderBetweenAccounts />}
                        </Fragment>
                    ))}
            </>
        );
    }, [fiLoginAccounts, selectedAccounts]);

    useEffect(() => {
        dispatch(setFirstBARender(false));
    }, [dispatch]);

    useEffect(() => {
        const _fiLoginAcctId = new URLSearchParams(location.search).get('fiLoginAcctId');

        _fiLoginAcctId &&
            customerId &&
            fiservToken &&
            checkAccountUpdateCompletedAction({
                id: customerId,
                requestId: fiservToken.requestId,
            }).then(async r => {
                if (!r.success) {
                    return;
                }
                await getIdpBankAccountsAction({ customerId, fiLoginAcctId: _fiLoginAcctId });
            });
    }, [
        checkAccountUpdateCompletedAction,
        customerId,
        fiservToken,
        getIdpBankAccountsAction,
        location.search,
    ]);

    useEffect(() => {
        if (!location.search.includes('fiLoginAcctId')) {
            route(AppRoutes.customerSelectYourBankAccount);
        }
    }, [route, location]);

    useEffect(() => {
        if (!paymentCode) return;

        setIsLoading(true);

        axios
            .get(ENDPOINT.prepaymentShortCode(paymentCode))
            .then(({ data }: PrepaymentByIdResponse) => {
                const isCancelled = data?.status === 'CANCELLED' || data?.status === 'EXPIRED';
                if (isCancelled) route(AppRoutes.transactionExpired);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }, [paymentCode, route]);

    const handleAddButtonClick = useCallback(async () => {
        if (!customerId) {
            return;
        }

        const addAccounts = async () => {
            if (selectedAccounts.length) {
                const promises = selectedAccounts.map(function (account) {
                    return addBankAccountAction({ bankAccountId: account.id.guid, customerId });
                });
                await Promise.all(promises);
            }
        };
        await addAccounts();
        route(AppRoutes.customerSelectYourBankAccount);
        return;
    }, [addBankAccountAction, customerId, route, selectedAccounts]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!(addBankAccountError || checkAccountUpdateCompletedError || getIdpBankAccountsError);
    }, [addBankAccountError, checkAccountUpdateCompletedError, getIdpBankAccountsError]);

    const isAddButtonDisabled = useMemo(() => {
        return !fiLoginAccounts || fiLoginAccounts.length === 0;
    }, [fiLoginAccounts]);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        if (fiLoginAccounts?.length === 1) {
            const _addBankAccountAction = async () => {
                await addBankAccountAction({ bankAccountId: fiLoginAccounts[0].id.guid, customerId }).then(
                    r => {
                        if (!r.success) {
                            return;
                        }
                        route(AppRoutes.customerSelectYourBankAccount);
                    }
                );
            };
            _addBankAccountAction();
        }
    }, [addBankAccountAction, customerId, fiLoginAccounts, route]);

    return (
        <StyledPageWithPaddingContainer>
            <Loader
                isShowing={
                    isCheckAccountUpdateCompletedLoading ||
                    isGetIdpBankAccountsLoading ||
                    isAddBankAccountLoading ||
                    isLoading
                }
            />
            <BackButton routeUrl={AppRoutes.customerSelectYourBankAccount} />

            <Title
                isChargee={isBrandChargee}
                subtitle={TEXT_VARS.TITLE.CHECK_ONE_OR_MULTI}
                title={TEXT_VARS.TITLE.ADD_BANK_ACCOUNTS}
            />
            <StyledBodySection>
                {!fiLoginAccounts ||
                    (fiLoginAccounts.length === 0 && (
                        <StyledInfoText>{TEXT_VARS.COMMON_TEXT.NO_NEW_ACCOUNTS}</StyledInfoText>
                    ))}
                <BankAccounts />
            </StyledBodySection>
            <AppButton appButtonType="Continue" disabled={isAddButtonDisabled} onClick={handleAddButtonClick}>
                {TEXT_VARS.BUTTON.ADD}
            </AppButton>
            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </StyledPageWithPaddingContainer>
    );
};
