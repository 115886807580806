import React from 'react';

import { StyledStepBar } from 'src/components/step-bar/step-bar.styled';

type StepBarColors = 1 | 2 | 3;

export const StepBar: React.FC<{ step: StepBarColors }> = ({ step }) => {
    return (
        <StyledStepBar step={step}>
            <div className="left-point" />
            <div className="left-line" />
            <div className="middle-point" />
            <div className="right-line" />
            <div className="right-point" />
        </StyledStepBar>
    );
};
