import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, LINK_FLOW } from 'src/store/app/models';
import { CustomerType } from 'src/store/customer/models.ts';

export const INITIAL_STATE: AppState = {
    isPaymentCodeFlow: false,
    paymentCode: undefined,
    isFirstBankAccRender: true,
    virtualCustomerId: '',
};

const applicationReducer = createSlice({
    name: 'ApplicationState',
    initialState: INITIAL_STATE,
    reducers: {
        setPaymentCodeFlowFlag: (state, action: PayloadAction<boolean>) => {
            state.isPaymentCodeFlow = action.payload;
        },
        setPaymentCode: (state, action: PayloadAction<string>) => {
            state.paymentCode = action.payload;
        },
        setWelcomeMode: (state, action: PayloadAction<boolean>) => {
            state.isWelcomeFlow = action.payload;
        },
        setPaymentLinkFlow: (state, action: PayloadAction<LINK_FLOW>) => {
            state.linkFlow = action.payload;
        },
        setFirstBARender: (state, action: PayloadAction<boolean>) => {
            state.isFirstBankAccRender = action.payload;
        },
        setChangeFlow: (state, action: PayloadAction<boolean>) => {
            state.isChangeFlow = action.payload;
        },
        setCustomerTypeForChange: (state, action: PayloadAction<CustomerType>) => {
            state.customerTypeChangeFlow = action.payload;
        },
        setVirtualCustomerId: (state, action: PayloadAction<string>) => {
            state.virtualCustomerId = action.payload;
        },
        setBranding: (state, action: PayloadAction<string>) => {
            state.branding = action.payload;
        },
    },
});

export const {
    setPaymentCodeFlowFlag,
    setPaymentCode,
    setWelcomeMode,
    setPaymentLinkFlow,
    setFirstBARender,
    setChangeFlow,
    setCustomerTypeForChange,
    setVirtualCustomerId,
    setBranding,
} = applicationReducer.actions;
export default applicationReducer.reducer;
