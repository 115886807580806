import { useTheme } from '@mui/material/styles';

import { Img } from 'src/components/img';

import { getApplicationConfig } from 'src/configuration/config';

import { StyledHeader } from './header.styled';

const { brandName, images } = getApplicationConfig();

const imgStyles = { maxHeight: '60px' };

export const Header = () => {
    const theme = useTheme();

    return (
        <StyledHeader>
            <Img
                alt={brandName}
                src={theme.brandTheme?.brandProperties?.logoUrl || images?.appLogoText}
                style={imgStyles}
            />
        </StyledHeader>
    );
};
