import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import {
    RightArrow,
    StyledBackButton,
    StyledBackButtonComponent,
} from 'src/components/back-button/back-button.styled';

import { useAppRoute } from 'src/hooks/useAppRoute';

import { AppRoutes } from 'src/router/config';

import { StyledExtendable } from 'src/utils/sharedModels';

type Props = { routeUrl?: AppRoutes; action?: () => void; isHidden?: boolean } & StyledExtendable;

export const BackButton = ({ routeUrl, action, isHidden, ...otherProps }: Props) => {
    const navigate = useNavigate();
    const route = useAppRoute();

    const handleBackButtonClick = useCallback(() => {
        if (action) {
            action();
            return;
        }

        routeUrl ? route(routeUrl) : navigate(-1);
    }, [action, navigate, route, routeUrl]);

    return (
        <StyledBackButtonComponent isHidden={isHidden} onClick={handleBackButtonClick}>
            <StyledBackButton
                aria-label="go-back"
                edge="start"
                {...otherProps}
                className={`back-button ${otherProps.className || ''}`}
            >
                <RightArrow />
            </StyledBackButton>
            Back
        </StyledBackButtonComponent>
    );
};
